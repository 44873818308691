<template>
  <div>
    <header>
      <span>Benjamin DENIZART</span>
      <br>
      <a href="mailto:contact@osiris.mc">contact@osiris.mc</a>
    </header>
    <div class="flex-center position-ref full-height">
      <div class="content">
        <pre class="title m-b-md">
 ██████╗ ███████╗██╗██████╗ ██╗███████╗
██╔═══██╗██╔════╝██║██╔══██╗██║██╔════╝
██║   ██║███████╗██║██████╔╝██║███████╗
██║   ██║╚════██║██║██╔══██╗██║╚════██║
╚██████╔╝███████║██║██║  ██║██║███████║
 ╚═════╝ ╚══════╝╚═╝╚═╝  ╚═╝╚═╝╚══════╝
        </pre>
        <div class="links">
          <a href="https://osirismc.atlassian.net/wiki/spaces/WMS/overview" target="_blank">Base de connaissances</a>
          <a href="https://osirismc.atlassian.net/servicedesk/customer/portal/4" target="_blank">Centre de support</a>
          <a href="https://static.osiris.mc/wms/apk" target="_blank">APK</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'home'
}
</script>
<style>
header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 1;
}

.full-height {
    height: 100vh;
    min-height: 270px;
    background: #000;
}

.flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
}

.position-ref {
    position: relative;
}

.top-right {
    position: absolute;
    right: 10px;
    top: 18px;
}

.content {
    text-align: center;
}

.title {
    /* font-size: 84px; */
}


.links > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
}

.m-b-md {
    margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
  .links > a {
    display: block;
    padding: 7px;
    font-size: 18px;
  }

  .m-b-md {
    margin-bottom: 10px;
  }
}
</style>
